import React from 'react'

export default function Email() {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.666 6.33333H6.33268C4.59102 6.33333 3.18185 7.75833 3.18185 9.49999L3.16602 28.5C3.16602 30.2417 4.59102 31.6667 6.33268 31.6667H31.666C33.4077 31.6667 34.8327 30.2417 34.8327 28.5V9.49999C34.8327 7.75833 33.4077 6.33333 31.666 6.33333ZM31.666 12.6667L18.9993 20.5833L6.33268 12.6667V9.49999L18.9993 17.4167L31.666 9.49999V12.6667Z" fill="#028F99"/>
        </svg>

    )
}
