import React from 'react'

export default function instagram() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5 1.5H4.5C2.85 1.5 1.5 2.85 1.5 4.5V25.5C1.5 27.1515 2.85 28.5 4.5 28.5H25.5C27.15 28.5 28.5 27.1515 28.5 25.5V4.5C28.5 2.85 27.15 1.5 25.5 1.5ZM14.976 23.2845C17.1793 23.2841 19.2922 22.4086 20.85 20.8505C22.4078 19.2924 23.283 17.1793 23.283 14.976C23.283 14.469 23.2185 13.98 23.1285 13.5H25.5V24.324C25.5 24.4603 25.4731 24.5953 25.4208 24.7211C25.3685 24.847 25.2919 24.9613 25.1953 25.0575C25.0987 25.1536 24.9841 25.2298 24.858 25.2815C24.7319 25.3333 24.5968 25.3596 24.4605 25.359H5.5395C5.4032 25.3596 5.26813 25.3333 5.14204 25.2815C5.01595 25.2298 4.90132 25.1536 4.80473 25.0575C4.70815 24.9613 4.63151 24.847 4.57921 24.7211C4.52692 24.5953 4.5 24.4603 4.5 24.324V13.5H6.8235C6.732 13.98 6.6675 14.469 6.6675 14.976C6.6679 17.1794 7.54338 19.2925 9.10144 20.8506C10.6595 22.4086 12.7726 23.2841 14.976 23.2845ZM9.7845 14.976C9.7845 14.2942 9.91878 13.6192 10.1797 12.9893C10.4406 12.3594 10.823 11.7871 11.3051 11.3051C11.7871 10.823 12.3594 10.4406 12.9893 10.1797C13.6192 9.91878 14.2942 9.7845 14.976 9.7845C15.6578 9.7845 16.3328 9.91878 16.9627 10.1797C17.5926 10.4406 18.1649 10.823 18.6469 11.3051C19.129 11.7871 19.5114 12.3594 19.7723 12.9893C20.0332 13.6192 20.1675 14.2942 20.1675 14.976C20.1675 16.3529 19.6205 17.6733 18.6469 18.6469C17.6733 19.6205 16.3529 20.1675 14.976 20.1675C13.5991 20.1675 12.2787 19.6205 11.3051 18.6469C10.3315 17.6733 9.7845 16.3529 9.7845 14.976ZM24.4605 9H22.038C21.7629 8.99921 21.4994 8.88959 21.3049 8.6951C21.1104 8.50061 21.0008 8.23705 21 7.962V5.5365C21 4.9635 21.465 4.5 22.0365 4.5H24.459C25.035 4.5 25.5 4.9635 25.5 5.5365V7.9605C25.5 8.532 25.035 9 24.4605 9Z" fill="#028F99"/>
        </svg>

    )
}

