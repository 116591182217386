import React from 'react'

export default function error3lines() {
    return (
        <svg width={101 * '1rem'} height={89 * '1rem'} viewBox="0 0 101 89" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5273 51.5149L1.52734 0.287354M32.9813 63.8986L85.9813 14.8986M43.9732 87.5002L100.973 86.5002" stroke="#028F99" stroke-width="3"/>
        </svg>

    )
}
