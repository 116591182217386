import React from 'react'

export default function Github() {
    return (
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.7125 0 0 6.65288 0 14.8668C0 21.4453 4.29375 27.0018 10.2563 28.9716C11.0063 29.1017 11.2875 28.6557 11.2875 28.2654C11.2875 27.9123 11.2688 26.7416 11.2688 25.4965C7.5 26.1841 6.525 24.5859 6.225 23.7497C6.05625 23.3222 5.325 22.0028 4.6875 21.6497C4.1625 21.371 3.4125 20.6834 4.66875 20.6648C5.85 20.6462 6.69375 21.7426 6.975 22.1886C8.325 24.4372 10.4812 23.8054 11.3438 23.4151C11.475 22.4488 11.8688 21.7984 12.3 21.4267C8.9625 21.055 5.475 19.7728 5.475 14.0863C5.475 12.4695 6.05625 11.1315 7.0125 10.0908C6.8625 9.71915 6.3375 8.1953 7.1625 6.15112C7.1625 6.15112 8.41875 5.76087 11.2875 7.67497C12.4875 7.34046 13.7625 7.17321 15.0375 7.17321C16.3125 7.17321 17.5875 7.34046 18.7875 7.67497C21.6562 5.74229 22.9125 6.15112 22.9125 6.15112C23.7375 8.1953 23.2125 9.71915 23.0625 10.0908C24.0188 11.1315 24.6 12.4509 24.6 14.0863C24.6 19.7914 21.0938 21.055 17.7563 21.4267C18.3 21.8913 18.7688 22.7833 18.7688 24.1771C18.7688 26.1655 18.75 27.7637 18.75 28.2654C18.75 28.6557 19.0312 29.1203 19.7812 28.9716C22.759 27.9752 25.3465 26.0784 27.1796 23.5482C29.0127 21.0179 29.9991 17.9817 30 14.8668C30 6.65288 23.2875 0 15 0Z" fill="#028F99"/>
        </svg>

    )
}
