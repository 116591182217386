import React from 'react'

export default function errorMedium() {
    return (
        <svg width="834" height="812" viewBox="0 -100 834 812" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M880.524 74.2746C943.588 154.39 929.765 270.46 849.649 333.523C769.534 396.587 653.464 382.764 590.401 302.649C527.337 222.534 541.16 106.464 621.275 43.4002C701.39 -19.6637 817.46 -5.84074 880.524 74.2746ZM882.881 72.419C946.97 153.836 932.922 271.792 851.505 335.881C770.088 399.969 652.132 385.922 588.043 304.505C523.955 223.087 538.002 105.132 619.419 41.0429C700.837 -23.0458 818.792 -8.99821 882.881 72.419ZM52.0102 324.75L380.007 502.223L214.766 807.612L-113.231 630.139L52.0102 324.75ZM50.7993 320.684L384.073 501.012L215.977 811.679L-117.297 631.35L50.7993 320.684Z" fill="#EC4A27"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M578.752 466.698L417 321L419.008 318.771L580.76 464.469L578.752 466.698Z" fill="#028F99"/>
        </svg>


    )
}


