import React from 'react'

export default function ErrorBG() {
    return (
        <svg width="1885" height="782" viewBox="0 0 1885 782" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1164.23 220L863.001 521.228L860.879 519.106L1162.11 217.879L1164.23 220Z" fill="#028F99"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M739.737 662.128L72.4421 301.066L-263.934 922.738L403.362 1283.8L739.737 662.128ZM71.2312 297L-268 923.949L404.572 1287.87L743.804 660.917L71.2312 297Z" fill="#EC4A27"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1763.54 554.072C1897.26 448.808 1920.34 255.069 1815.07 121.344C1709.81 -12.3814 1516.07 -35.4541 1382.34 69.8096C1248.62 175.073 1225.55 368.812 1330.81 502.537C1436.07 636.263 1629.81 659.335 1763.54 554.072ZM1765.39 556.429C1900.42 450.14 1923.72 254.515 1817.43 119.488C1711.14 -15.5389 1515.52 -38.8362 1380.49 67.4523C1245.46 173.741 1222.16 369.366 1328.45 504.393C1434.74 639.42 1630.37 662.717 1765.39 556.429Z" fill="#EC4A27"/>
        </svg>

    )
}
