import React from 'react'

export default function behance() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_61_239)">
            <path d="M29.3333 9.33366H20V6.66699H29.3333V9.33366ZM31.6347 22.667C31.0453 24.3963 28.9293 26.667 24.8333 26.667C20.7347 26.667 17.4147 24.3617 17.4147 19.1003C17.4147 13.887 20.5147 11.207 24.7027 11.207C28.812 11.207 31.3213 13.583 31.8693 17.1083C31.9733 17.783 32.0147 18.6923 31.996 19.9617H21.2933C21.4667 24.243 25.9373 24.3777 27.4107 22.667H31.6347ZM21.3867 17.3337H28.0067C27.8667 15.271 26.492 14.375 24.704 14.375C22.7493 14.375 21.668 15.399 21.3867 17.3337ZM8.62133 26.651H0V6.69499H9.27067C16.572 6.80299 16.7107 13.9537 12.8973 15.903C17.512 17.583 17.6667 26.651 8.62133 26.651ZM4 14.667H8.77867C12.1227 14.667 12.6533 10.667 8.36267 10.667H4V14.667ZM8.52133 18.667H4V22.6883H8.45467C12.528 22.6883 12.2787 18.667 8.52133 18.667Z" fill="#028F99"/>
            </g>
            <defs>
            <clipPath id="clip0_61_239">
            <rect width="32" height="32" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    )
}

