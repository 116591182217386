import React from 'react'

export default function LinkedIn() {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33891e-07 2.25573C3.33891e-07 1.65747 0.237657 1.08372 0.660688 0.66069C1.08372 0.237659 1.65747 3.0072e-06 2.25573 3.0072e-06H24.7418C25.0383 -0.000481215 25.332 0.0575166 25.606 0.170676C25.88 0.283835 26.129 0.449933 26.3388 0.659461C26.5486 0.868989 26.7149 1.11783 26.8284 1.39174C26.9418 1.66565 27.0002 1.95925 27 2.25573V24.7418C27.0003 25.0384 26.9422 25.332 26.8289 25.6061C26.7156 25.8801 26.5494 26.1291 26.3397 26.3389C26.1301 26.5486 25.8812 26.715 25.6072 26.8284C25.3332 26.9419 25.0396 27.0002 24.743 27H2.25573C1.9594 27 1.66597 26.9416 1.39221 26.8282C1.11846 26.7147 0.869733 26.5485 0.660253 26.3389C0.450774 26.1293 0.284644 25.8805 0.171355 25.6067C0.0580663 25.3328 -0.00016089 25.0394 3.33891e-07 24.743V2.25573ZM10.6871 10.2944H14.3431V12.1304C14.8709 11.0749 16.2209 10.125 18.2495 10.125C22.1388 10.125 23.0605 12.2273 23.0605 16.0846V23.2298H19.1246V16.9634C19.1246 14.7665 18.5969 13.527 17.2567 13.527C15.3974 13.527 14.6242 14.8635 14.6242 16.9634V23.2298H10.6871V10.2944ZM3.93709 23.0617H7.87418V10.125H3.93709V23.0617ZM8.4375 5.90564C8.44492 6.24274 8.38494 6.57792 8.26107 6.89152C8.13719 7.20513 7.95193 7.49083 7.71615 7.73186C7.48036 7.97289 7.19881 8.1644 6.88801 8.29515C6.57721 8.42589 6.24343 8.49324 5.90625 8.49324C5.56907 8.49324 5.23529 8.42589 4.92449 8.29515C4.61369 8.1644 4.33214 7.97289 4.09635 7.73186C3.86057 7.49083 3.67531 7.20513 3.55143 6.89152C3.42756 6.57792 3.36758 6.24274 3.375 5.90564C3.38957 5.24396 3.66265 4.61429 4.13577 4.15148C4.60888 3.68867 5.24441 3.42952 5.90625 3.42952C6.56809 3.42952 7.20362 3.68867 7.67673 4.15148C8.14985 4.61429 8.42293 5.24396 8.4375 5.90564Z" fill="#028F99"/>
        </svg>
    )
}


