import React from 'react'

export default function errorSmall() {
    return (
    <svg width={620 * '1rem'} height={383 * '1rem'} viewBox="140 -40 360 383" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M571.612 353.737C622.972 313.308 631.833 238.899 591.405 187.54C550.976 136.18 476.567 127.318 425.207 167.747C373.848 208.175 364.986 282.584 405.415 333.944C445.843 385.304 520.252 394.165 571.612 353.737ZM573.467 356.094C626.129 314.641 635.215 238.346 593.762 185.684C552.309 133.022 476.013 123.936 423.352 165.39C370.69 206.843 361.604 283.138 403.057 335.8C444.511 388.461 520.806 397.547 573.467 356.094Z" fill="#EC4A27"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M248.688 109.451L85.9527 21.398L4.06618 172.736L166.801 260.789L248.688 109.451ZM84.7418 17.3318L0 173.947L168.012 264.856L252.754 108.24L84.7418 17.3318Z" fill="#EC4A27"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M429 2.33184L320 90.5594L318.112 88.2275L427.112 0L429 2.33184Z" fill="#028F99"/>
    </svg>


    )
}


